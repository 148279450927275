<template>
<body>
    <div class="background">
        <div class="shape"></div>
        <div class="shape"></div>
    </div>
    <section ref="form">
              <div>
                <h3 style="margin-bottom: -15px; margin-top: -15x; font-size: 40px;">Sign up</h3>
                <div style="width: 100%; align-items:center; margin-top: 10%; margin-bottom: -10%; margin-left: 25%;">
                    <strong><h5>For your 7 day free trial</h5></strong>
                </div>  
              </div>
              <label for="Name">
                  Username
              </label>
              <input type="text" placeholder="Username" v-model="name" ref="name" @change="handleNameChnage"/>
              <div class="mini-feedback" ref="fname"></div>
                <div style="margin-top: -20px;">
              <label for="Email">Email</label>
              <input type="email" placeholder="Email" v-model="email" ref="email" @change="handleEmailChange"/>
              <div class="mini-feedback" ref="femail"></div>
              </div>
                <div style="margin-top: -20px;">
              <label for="Pass">Password</label>
              <input type="password" placeholder="Password" v-model="password" ref="password" @change="handlepasschange"/>
              <div class="mini-feedback" ref="fpass"></div>
              </div>
      
              <button id="log_in_button" type="button" class="glow-on-hover" @click="handleSignUp">Sign Up</button>
            
              <div ref="fatastic" class="feedback" id="feedback" style="color: red; size: 12px; width: 100%"></div>
            <div style="width: 100%; height: 3px; background-color: #000000; float: center; margin-top: 20px; margin-bottom: -10px;"></div>
            <div>
                <GoogleLogin :callback="handleGoogleSignup">
                    <button style="display: flex; align-items: center; height: 4%; width: 100%; border-radius: 10px; margin-left: 5%;">
                        <div class="nsm7Bb-HzV7m-LgbsSe-Bz112c" style="width: 22px; height: 22px;"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="LgbsSe-Bz112c"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg></div>
                        <div style="padding: 20px;">
                            <span style="color: black; font-size: auto;">Sign up with google</span>
                        </div>
                    </button>
                </GoogleLogin>
            </div>
            <div style="margin-top: 10px; font-size: 15px; width: 100%; text-align: center;">Already have an account? <router-link :to="{name: 'login'}"> Log in</router-link></div>
        <socialcomp />
    </section>
</body>
</template>

<script>
import socialcomp from '../components/socialcomp.vue'
export default {
    components: {
        socialcomp
    },
    data(){
        return {
            name: '',
            password: '',
            email: '',
            postData: '',
            names: [],
            aff_name: '',
            height: 730,
            emailnotused: false
        }
    },
    beforeCreate(){
        document.title = 'Sign-up';
    },
    async mounted(){
        const urlParams = window.location.search;
        const urlRlParams = new URLSearchParams(urlParams);

        let aff_name = urlRlParams.get('i');
        if (!aff_name){
            aff_name = '1';
        }
        this.aff_name = aff_name;
    },
    methods: {
        opencheckout(userdata){
                fetch('https://indertct.me/api/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ Username: userdata, aff: this.aff_name })
                })
                .then(res => res.json())
                .then(data => {
                if(data.code == 0){
                const stripe = Stripe('pk_live_51PgOxnJZlfnEYHCxKMh6QX3qmNF2UxiSjndGi9UlgLXMQewOv1gKJ8dvjfOPvolXcacQzaNfpA7OSQl3E3bCCoh000QL7nrDUy');
                const { error } = stripe.redirectToCheckout({ sessionId: data.data });

                if (error) {
                console.error('Error:', error);
                }
                }
                else {
                    console.log(data.code, data.msg);
                }
                })
                .catch(err => console.log(err))
        },
        handleEmailChange(){
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
                this.$refs.femail.innerHTML = 'Please insert a valid email';
                this.$refs.femail.style.color = 'red';
                return false;
            }
            else {
                this.$refs.femail.innerHTML = 'Looks good';
                this.$refs.femail.style.color = 'green';
                return true;
            }
        },
        handleNameChnage(){
            if(this.name.includes('@')){
                return false;
            }
            let returns;
            fetch('https://indertct.me/api/get_names', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ Name: this.name })})
                .then(res => res.json())
                .then(data => {
                    if (data.data){
                        this.$refs.fname.innerHTML = 'Looks good';
                        this.$refs.fname.style.color = 'green';
                        returns = true;
                    } else {
                        this.$refs.fname.innerHTML = 'Username already taken';
                        this.$refs.fname.style.color = 'red';
                        returns = false
                    }
                    this.height += 30;
                    this.$refs.form.style.height = this.height.toString() + "px";
                })
                .catch(err => {
                    console.log(err)
                })
            return returns;
        },
        handlepasschange(){
            this.height += 30;
            this.$refs.form.style.height = this.height.toString() + "px";
            if(this.password.length >= 7){
                this.$refs.fpass.innerHTML = 'Looks good';
                this.$refs.fpass.style.color = 'green';
                return true
            } else {
                this.$refs.fpass.innerHTML = 'Password must be at least 7 characters long';
                this.$refs.fpass.style.color = 'red';
                return false
            }
        },
        handleGoogleSignup(response) {
            const accessToken = response.code;
            fetch('https://indertct.me/api/googleOauthSignups', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ code: accessToken })})
                .then(res => res.json())
                .then(data => {
                    if (data.code == 0){
                        fetch('https://indertct.me/api/sign_up', {method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify({Name: this.aff_name})})
                            .then(res => res.json())
                            .then(dat => {
                                this.opencheckout(data.data);
                            })
                            .catch(err => console.log(err))
                    } else if (data.code == 1 || data.code == 2){
                        console.log(data.msg);
                        this.$refs.fatastic.innerHTML = 'Something went wrong: ' + data.msg;
                        this.height += 40;
                        this.$refs.form.style.height = this.height.toString() + "px";
                    } else if (data.code == 3){
                        this.$refs.fatastic.innerHTML = 'Someone already used this google acccount in IndertCT';
                        this.height += 40
                        this.$refs.form.style.height = this.height.toString() + "px";
                    }
                })
                .catch(err => console.log(err))
        },
        async handleSignUp(){
            var email = await this.handleEmailChange12;
            var name = this.handleNameChnage12;
            var pass = this.handlepasschange12;
            if (email && name && pass) {
                let code;
                fetch('https://indertct.me/api/sign_up', {method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify({Name: this.aff_name})})
                    .then(res => res.json())
                    .then(data => code = data.code)
                    .catch(err => console.log(err))

                fetch('https://indertct.me/api/MakeACCTemp', {method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify({Name: this.name, email: this.email, Password: this.password})})
                    .then(res => res.json())
                    .then(data => code = data.code)
                    .catch(err => console.log(err))  
                              
                this.opencheckout(this.name);
            } else {
                console.log('Something is not right')
            }
        }
    },
    computed: {
        handleEmailChange12(){
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
                this.$refs.femail.innerHTML = 'Please insert a valid email';
                this.$refs.femail.style.color = 'red';
                return false;
            }
            else {
                this.$refs.femail.innerHTML = 'Looks good';
                this.$refs.femail.style.color = 'green';
               return true;
            }
        },
        async handleNameChnage12(){
            if(this.name.includes('@')){
                return false;
            }
            let returns;
            await fetch('https://indertct.me/api/get_names', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ Name: this.name })})
                .then(res => res.json())
                .then(data => {
                    if (data.data){
                        this.$refs.fname.innerHTML = 'Looks good';
                        this.$refs.fname.style.color = 'green';
                        returns = true;
                    } else {
                        this.$refs.fname.innerHTML = 'Username already taken';
                        this.$refs.fname.style.color = 'red';
                        returns = false
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            return returns;
        },
        handlepasschange12(){
            if(this.password.length >= 7){
                this.$refs.fpass.innerHTML = 'Looks good';
                this.$refs.fpass.style.color = 'green';
                return true
            } else {
                this.$refs.fpass.innerHTML = 'Password must be at least 7 characters long';
                this.$refs.fpass.style.color = 'red';
                return false
            }
        }
    }
}
</script>

<style scoped media="screen">
*,
      *:before,
      *:after{
          padding: 0;
          margin: 0;
          box-sizing: border-box;
      }
      body{
          background-color: #080710;
      }
      .background{
          width: 430px;
          height: 520px;
          position: absolute;
          transform: translate(-50%,-50%);
          left: 50%;
          top: 50%;
          color: black;
      }
      .background .shape{
          height: 200px;
          width: 200px;
          position: absolute;
          border-radius: 50%;
      }
      .shape:first-child{
          background: linear-gradient(
              #1845ad,
              #23a2f6
          );
          left: -80px;
          top: -80px;
      }
      .shape:last-child{
          background: linear-gradient(
              to right,
              #ff512f,
              #f09819
          );
          right: -30px;
          bottom: -80px;
      }
      section{
          height: 730px;
          width: 400px;
          background-color: rgba(255,255,255,0.13);
          position: absolute;
          transform: translate(-50%,-50%);
          top: 50%;
          left: 50%;
          border-radius: 10px;
          backdrop-filter: blur(10px);
          border: 2px solid rgba(255,255,255,0.1);
          box-shadow: 0 0 40px rgba(8,7,16,0.6);
          padding: 50px 35px;
      }
      section *{
          font-family: 'Poppins',sans-serif;
          color: #ffffff;
          letter-spacing: 0.5px;
          outline: none;
          border: none;
      }
      section h3{
          font-size: 32px;
          font-weight: 500;
          line-height: 42px;
          text-align: center;
      }
      
      label{
          display: block;
          margin-top: 30px;
          font-size: 16px;
          font-weight: 500;
          float: left;
          padding: 10px;
      }
      input{
          display: block;
          height: 50px;
          width: 100%;
          background-color: rgba(255,255,255,0.07);
          border-radius: 3px;
          padding: 0 10px;
          margin-top: 8px;
          font-size: 14px;
          font-weight: 300;
      }
      ::placeholder{
          color: #e5e5e5;
      }
      button{
        display: block;
        margin: auto;
        margin-top: 30px;
        width: 100%;
        background-color: #ffffff;
        color: #080710;
        padding: 0 0;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        cursor: pointer;
    }
    
      .glow-on-hover {
        width: 220px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }
    
    .glow-on-hover:before {
        content: '';
        background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
        position: absolute;
        top: -2px;
        left:-2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        border-radius: 10px;
    }
    
    .glow-on-hover:active {
        color: #000
    }
    
    .glow-on-hover:active:after {
        background: transparent;
    }
    
    .glow-on-hover:hover:before {
        opacity: 1;
    }
    
    .glow-on-hover:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    div.feedback{
        margin: auto;
        width: 50%;
        text-align: center;
        margin-top: 5px;
    }

    div.mini-feedback{
        color:#ff0000;
        font-size: 13px;
        margin-top: 4px;
        margin-bottom: -10px;
    }
    
    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }

    div.feedback{
        color: red;
        margin-top: 10px;
        font-size: 15px;
        margin: auto;
        text-align: center;
    }
.google-log-in {
    color: black;
}
.nsm7Bb-HzV7m-LgbsSe-BPrWId{
    color: black;
}
</style>