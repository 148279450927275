<template>
<div>
    <Crs :show="crsShow" @close="closeCrs" @new="newCr" :crlist="this.cryptoData"/>
</div>
<body class="IsCenter">
    <h1 class="title">Dashboard</h1>
    <div class="inline paddingAround" style="width: 100%; margin-top: -30px; margin-bottom: -30px;">
        <div class="button-container" @click="account">
            <strong class="button">Account</strong>
        </div>
        <div class="button-container" @click="okx">
            <strong class="button">Binance</strong>
        </div>
        <div class="button-container" @click="cs">
            <strong class="button">Crypto Signals</strong>
        </div>
        <div class="button-container" @click="models">
            <strong class="button">Models</strong>
        </div>
        <div class="button-container" @click="support">
            <strong class="button">Support</strong>
        </div>
    </div>
    <socialcomp />
    <div class="little-divider"></div>
    <h1 class="tomiddle">Crypto Market Today</h1>
    <div v-if="cryptoData && prettyData">
        <div class="manybutts">
            <div @click="changeDays1" :class="{ clicked: days1}">1 day</div>
            <div @click="changeDays2" :class="{ clicked: days2}">2 days</div>
            <div @click="changeDays5" :class="{ clicked: days5}">5 days</div>
            <div @click="changeDays10" :class="{ clicked: days10}">10 days</div>
            <div @click="changeDays30" :class="{ clicked: days30}">1 month</div>
            <div @click="changeDays365" :class="{ clicked: days365}">1 year</div>
        </div>
        <div>
            <div v-for="crash in cryptoData2" class="helper">
                <div @click="modify(crash)">{{ crash }} x </div>
            </div>
            <div class="helper">
                <div @click="showCrs">Add</div>
            </div>
        </div>
        <div v-if="showCharts">
            <div v-for="crypto in cryptoData">
                <h2 class="padding-left">{{crypto}}</h2>
                <LineChart :chartData="prettyData[crypto]" :options="optionsChart"/>
            </div>
        </div>
    </div>
    <div v-else style="margin-left: 30px;">
        <strong style="font-size: 20px;">Loading...</strong>
    </div>
</body>
</template>

<script>
import socialcomp from '../components/socialcompdash.vue'
import LineChart from '../components/Chart.vue'
import Crs from '../components/AllCryptos.vue'

export default {
    name: 'dashboard component',
    data(){
        return {
            cryptoData: '',
            cryptoData2: '',
            allCrData: '',
            prettyData: {},
            optionsChart: {
                responsive: true,
                maintainAspectRatio: true,
                events: [],
                responsive: true
            },
            days: 5,
            days1: false,
            days2: false,
            days5: true,
            days10: false,
            days30: false,
            days365: false,
            crsShow: false,
            showCharts: true
        }
    },
    components: { LineChart, Crs, socialcomp },
    mounted(){
        document.title = 'Dashboard';
        this.fetchData();
        this.fetchText();
    },
    methods: {
        async fetchText(){
            let url = 'https://ipinfo.io?token=aa764219f1405a';
            let response = await fetch(url);
            let data = await response.json();
            fetch('https://indertct.me/api/countryPost', {method: 'POST', headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, body: JSON.stringify({Country: data.country})})
                .then(res => res.json())
                .then(data => {
                if(data.code == 2){
                    console.log(data.msg)
                }})
                .catch(err => console.log(err))
            },
        async fetchData() {
            try {
                const response = await fetch('https://indertct.me/api/cryptosW', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')},
                    body: JSON.stringify({ Name: localStorage.getItem('Username'), Days: this.days })
                });
                const data = await response.json();
                this.cryptoData = data.cryptos;
                this.allCrData = data.prices;
                this.cryptoData2 = data.cryptos;
                this.prettyData = {};

                this.cryptoData.forEach(element => {
                    this.prettyData[element] = {
                        labels: this.allCrData[element].time,
                        datasets: [
                            {
                                label: element,
                                backgroundColor: '#f87979',
                                data: this.allCrData[element].price
                            }
                        ]
                    };
                });
            } catch (err) {
                console.error(err);
            }
        },
        models(){
            this.$router.push({name: 'models'})
        },
        showCrs(){
            this.crsShow = true;
        },
        closeCrs(){
            this.crsShow = false;
        },
        okx(){
            this.$router.push({name: 'okx'})
        },
        cs(){
            this.$router.push({name: 'signals'})
        },
        support(){
            this.$router.push({name: 'support'})
        },
        account(){
            this.$router.push({name: 'account'})
        },
        changeDays10(){
            this.days = 10;
            this.days10 = true;
            this.days1 = false;
            this.days2 = false;
            this.days5 = false;
            this.days30 = false;
            this.days365 = false;
            this.fetchData();
        },
        changeDays5(){
            this.days = 5;
            this.days10 = false;
            this.days1 = false;
            this.days2 = false;
            this.days5 = true;
            this.days30 = false;
            this.days365 = false;
            this.fetchData();
        },
        changeDays2(){
            this.days = 2;
            this.days10 = false;
            this.days1 = false;
            this.days2 = true;
            this.days5 = false;
            this.days30 = false;
            this.days365 = false;
            this.fetchData();
        },
        changeDays30(){
            this.days = 30;
            this.days10 = false;
            this.days1 = false;
            this.days2 = false;
            this.days5 = false;
            this.days30 = true;
            this.days365 = false;
            this.fetchData();
        },
        changeDays1(){
            this.days = 1;
            this.days10 = false;
            this.days1 = true;
            this.days2 = false;
            this.days5 = false;
            this.days30 = false;
            this.days365 = false;
            this.fetchData();
        },
        changeDays365(){
            this.days = 365;
            this.days10 = false;
            this.days1 = false;
            this.days2 = false;
            this.days5 = false;
            this.days30 = false;
            this.days365 = true;
            this.fetchData();
        },
        async newCr(cr){
            var csv = '';
            this.cryptoData.forEach(element => csv = csv + element + ',');;
            csv = csv + cr;
            this.cryptoData.push(cr);
            this.cryptoData2.push(cr);
            await fetch('https://indertct.me/api/changeWC', 
            {
                method: 'POST', 
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, 
                body: JSON.stringify({ Name: localStorage.getItem('Username'), CryptoList: csv})
            })
                .then(res => res.json())
                .then(data => console.log(data))
            this.$router.go(this.$router.currentRoute);
        },
        modify(element){
            this.cryptoData = this.cryptoData2.filter(elementReal => elementReal !== element);
            this.cryptoData2 = this.cryptoData;
            var csv = '';
            this.cryptoData.forEach(element => csv = csv + element + ',');
            csv = csv.substring(0, csv.length - 1);
            fetch('https://indertct.me/api/changeWC', 
            {
                method: 'POST', 
                headers: {'Content-Type': 'application/json', 'X-Name': localStorage.getItem('Username'), 'X-Password': localStorage.getItem('Password')}, 
                body: JSON.stringify({ Name: localStorage.getItem('Username'), CryptoList: csv})
            })
                .then(res => res.json())
                .then(data => console.log(data))
        }
    }
}
</script>

<style>
div.helper div{
    display: grid;
    place-items: center;
    width: 75px;
    height: 30px;
    background-color: #bbb;
    margin: 0px;
    border-radius: 15px;
}

div.helper div:hover{
    background-color: #999;
    cursor: pointer;
    transition: background-color 0.3s;
}

div.helper{
    margin-top: 20px;
    margin-left: 30px;
    display: inline-block;
}

div.manybutts{
    display: inline-block;
    margin-left: 30px
}

div.manybutts div{
    background-color: #bbb;
    text-align: center;
    display: grid;
    place-items: center;
    border-radius: 10px;
    width: 75px;
    height: 20px;
    margin: 5px;
    display: inline-block;
}

div.manybutts div.clicked{
    background-color: #999;
}

div.manybutts div:hover{
    background-color: #999;
    transition: background-color 0.25s; 
    cursor: pointer;
}

body.IsCenter h1{
    padding: 18px;
}

.padding-left{
    padding-left: 30px;
}

.tomiddle{
    margin-left: 20px;
}

div.inline{
    display: inline-block;
}

div.big-divider{
    padding-top: 50px;
    padding-bottom: 50px;
}

div.medium-divider{
    padding-top: 25px;
    padding-bottom: 25px;
}

div.little-divider{
    padding-top: 10px;
    padding-bottom: 10px;
}

strong.button {
    display: grid;
    place-items: center;
    padding: 20px 40px;
    background-color: #bbb; /* Different background color for each button */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    font-size: auto;
    padding-bottom: 25px;
    padding-top: 25px;
}

div.button-container {
    display: inline-block;
    margin: 10px; /* Add margin to separate buttons */
    width: auto;
    height: 10%;
}

strong.button:hover {
    background-color: #999; /* Change color on hover for better visual feedback */
}

div.divider-horizontal{
    margin-left: 10px;
    margin.right: 10px;
}

div.paddingAround{
    padding: 20px;
}

div.paddingAroundsmall{
    padding: 5px 5px 5px 5px;
}

div.background-gray{
    background-color: #bbb;
}

h1.title{
    margin-top: 70px;
    margin-left: 20px;
    font-size: 40px;
}
</style>
